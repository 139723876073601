import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const UserRoute = ({
  allowProvost,
  allowCollege,
  allowDepartment,
  allowDars,
  checkCollege = false,
  children,
}) => {
  const role = useSelector((state) => state.user.role);
  const college = useSelector((state) => state.user.college);
  const location = useLocation();

  const getInitialParams = () => {
    const params = new URLSearchParams(location.search);
    const paramsObject = {};

    for (let [key, value] of params.entries()) {
      paramsObject[key] = value;
    }

    return paramsObject;
  };

  const queryParams = getInitialParams();

  const isAllowed =
    !!role &&
    ((role.includes('PROVOST') && allowProvost) ||
      (role.includes('COLLEGE') &&
        allowCollege &&
        (!checkCollege || (checkCollege && queryParams.college === college))) ||
      (role.includes('DEPARTMENT') && allowDepartment) ||
      (role.includes('DARS') && allowDars));

  return isAllowed ? children : <Navigate to="/" replace />;
};

export default UserRoute;
