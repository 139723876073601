import { useQuery } from '@tanstack/react-query';
import { fetchDplColleges } from 'apis/dplAPIs';
import RecallRequestList from 'components/RecallRequestList';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const RecallRequests = () => {
  const [collegeName, setCollegeName] = useState([]);
  // const [error, setError] = useState(null);
  const location = useLocation();

  const getInitialParams = () => {
    const params = new URLSearchParams(location.search);
    const paramsObject = {};

    for (let [key, value] of params.entries()) {
      paramsObject[key] = value;
    }

    return paramsObject;
  };

  const [queryParams, setQueryParams] = useState(getInitialParams());

  const {
    data: fetchCollegesData,
    error: fetchCollegesError,
    // isError: fetchCollegesIsError,
    isSuccess: fetchCollegesIsSuccess,
  } = useQuery({
    queryKey: ['colleges'],
    queryFn: fetchDplColleges,
  });

  useEffect(() => {
    // if (fetchCollegesError) setError(fetchCollegesError.message);
    if (fetchCollegesIsSuccess) {
      const collegeFound = fetchCollegesData.find(
        (college) => college.acadOrgCode === queryParams.college
      );

      if (!!collegeFound) setCollegeName(collegeFound.alphaSortDescription);
    }
  }, [
    fetchCollegesData,
    fetchCollegesError,
    fetchCollegesIsSuccess,
    queryParams.college,
  ]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    console.log('params', params);
    const paramsObject = {};

    for (let [key, value] of params.entries()) {
      paramsObject[key] = value;
    }

    setQueryParams(paramsObject);
  }, [location.search]);

  return (
    <div className="container-xl">
      <div className="my-4">
        <div className="mb-3">
          <Link to={'/'} className="text-maroon">
            Home
          </Link>
          <span className="text-gray-5 mx-1">/</span>
          <Link
            to={
              queryParams.college
                ? `/programs?college=${queryParams.college}`
                : '/programs'
            }
            className="text-maroon"
          >
            {queryParams.college ? collegeName : 'All colleges/schools'}
          </Link>
          <span className="text-gray-5 mx-1">/</span>
          Recall requests
        </div>
        <RecallRequestList
          collegeCode={queryParams.college}
          collegeName={collegeName}
          programType={queryParams.program}
        />
      </div>
    </div>
  );
};

export default RecallRequests;
