import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState, Suspense, useEffect } from 'react';
import { classNames } from 'utils';

const TabPanel = ({
  tabs,
  initialTab = null,
  icons = null,
  currentTab = null,
  setCurrentTab = null,
  largeText = false,
  showExtraBorder = true,
  children,
}) => {
  const [activeTab, setActiveTab] = useState(
    initialTab !== null ? initialTab : 0
  );

  const handleTabClick = (index) => {
    setActiveTab(index);

    if (setCurrentTab !== null) setCurrentTab(index);
  };

  useEffect(() => {
    if (currentTab !== null) setActiveTab(currentTab);
  }, [currentTab]);

  return (
    <>
      <div className="d-flex">
        {tabs.map((tab, index) => (
          <div
            className={classNames(
              activeTab === index
                ? 'active-tab'
                : 'inactive-tab border-bottom border-gray-4',
              largeText ? 'fs-4' : 'py-1',
              !showExtraBorder && 'flex-grow-1',
              'tab px-2 d-flex gap-1 justify-content-center fw-bold'
            )}
            key={index}
            onClick={() => handleTabClick(index)}
            role="button"
          >
            {icons && icons[index] && (
              <FontAwesomeIcon
                icon={icons[index]}
                style={{ marginTop: '4px' }}
              />
            )}
            {tab}
          </div>
        ))}
        {showExtraBorder && (
          <div className="flex-grow-1 border-bottom border-gray-4" />
        )}
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        {children.map(
          (section, index) =>
            activeTab === index && <Fragment key={index}>{section}</Fragment>
        )}
      </Suspense>
    </>
  );
};

export default TabPanel;
