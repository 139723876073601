import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import StaticData from './StaticData';
import { classNames, getCoursesDescr } from 'utils';
import { Button } from '@asu/components-core/dist/libCore.es';
import { Modal } from 'react-bootstrap';
import Component from './Component';
import { useMutation, useQuery } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import useServiceAuth from 'auth/useServiceAuth';
import { setAll } from 'state/slices/templateSlice';
import {
  setChecksheetAll,
  setChecksheetRequirementList,
} from 'state/slices/checksheetSlice';
import { setValidationAll } from 'state/slices/validationSlice';
import { checksheetValidationGet } from 'apis/validationAPIs';
import {
  checksheetRequestRecall,
  // checksheetGet,
  checksheetUpdate,
  checksheetUpdateProgress,
  checksheetLock,
} from 'apis/checksheetAPIs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCircle,
  faLock,
  faXmark,
  faTrash,
  faBookOpen,
} from '@fortawesome/free-solid-svg-icons';
import ProgressUpdateForm from './ProgressUpdateForm';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

const statusCircle = {
  DEPARTMENT: {
    color: 'text-gray-4',
  },
  COLLEGE: {
    color: 'text-gray-7',
  },
  PROVOST: {
    color: 'text-warning',
  },
  DARS: {
    color: 'text-info',
  },
  COMPLETE: {
    color: 'text-success',
  },
};

const ChecksheetForm = ({
  checkId,
  checksheetQuery,
  program,
  isLocked,
  holdsLock,
  refetchHistory,
  currentTab,
  setCurrentTab,
}) => {
  const {
    data: checksheetData,
    error: getChecksheetError,
    isError: getChecksheetIsError,
    isSuccess: getChecksheetIsSuccess,
    isPending,
    fetchStatus,
    refetch: refetchChecksheet,
  } = checksheetQuery;
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const idArray = checkId.split('*');
  const paramAcadSubplan = idArray[3];
  const owners = [];
  let mainOwner = {};
  let componentIdNames = [];

  const [previousReview, setPreviousReview] = useState('');
  const [nextReview, setNextReview] = useState('');
  const [subplanName, setSubplanName] = useState('');
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [readOnly, setReadOnly] = useState(false);
  const [isValid, setIsValid] = useState(null);
  const [preventLock, setPreventLock] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [modalView, setModalView] = useState('');
  const [dataToModal, setDataToModal] = useState({});
  const [templateNotFound, setTemplateNotFound] = useState(false);
  const [activeName, setActiveName] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const previewUrl = 'https://degrees-qa.apps.asu.edu/checksheet';

  const historyRequirements = useSelector(
    (state) => state.history.requirements
  );
  const historySubsections = useSelector((state) => state.history.subsections);
  const historyDeleted = useSelector((state) => state.history.deleted);

  // const [historyRequirements, setHistoryRequirements] = useState({});
  // const [historySubsections, setHistorySubsections] = useState({});
  // const [historyDeleted, setHistoryDeleted] = useState({});

  const role = useSelector((state) => state.user.role);
  const userCollege = useSelector((state) => state.user.college);
  const userDepartment = useSelector((state) => state.user.department);

  const checksheetId = useSelector((state) => state.checksheet.checksheetId);
  const checksheetName = useSelector(
    (state) => state.checksheet.checksheetName
  );
  const year = useSelector((state) => state.checksheet.year);
  const programType = useSelector((state) => state.checksheet.programType);
  const acadplan = useSelector((state) => state.checksheet.acadplan);
  const acadsubplan = useSelector((state) => state.checksheet.acadsubplan);
  const college = useSelector((state) => state.checksheet.college);
  const department = useSelector((state) => state.checksheet.department);
  const checksheetRequirementList = useSelector(
    (state) => state.checksheet.checksheetRequirementList
  );
  const courseLists = useSelector((state) => state.checksheet.courseLists);
  const progress = useSelector((state) => state.checksheet.progress);
  const lockedBy = useSelector((state) => state.checksheet.lockedBy);
  const recalledBy = useSelector((state) => state.checksheet.recalledBy);

  const templateId = useSelector((state) => state.template.templateId);
  const templateComponentIds = useSelector(
    (state) => state.template.templateComponentIds
  );
  const templateComponentObjects = useSelector(
    (state) => state.template.templateComponentObjects
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  if (
    templateComponentObjects &&
    Object.keys(templateComponentObjects).length > 0
  ) {
    Object.keys(templateComponentObjects).forEach((key) => {
      // Get the component object using the current key
      const componentObject = templateComponentObjects[key];

      // Parse the details of the component object to extract the componentName
      const details = JSON.parse(componentObject.details);
      const componentId = componentObject.sk;
      const componentName = details.componentName;
      const component = {
        componentId: componentId,
        componentName: componentName,
      };
      // Add componentName to the array
      componentIdNames.push(component);
    });

    // Now componentNames array contains all the component names
    // console.log('Component Names:', componentIdNames);
  }

  if (!!program?.owners?.length) {
    let highestPercent = 0;
    program.owners.forEach((programOwner) => {
      if (
        owners.filter(
          (owner) => owner.collegeAcadOrg === programOwner.collegeAcadOrg
        ).length === 0
      ) {
        owners.push({
          collegeAcadOrg: programOwner.collegeAcadOrg,
          collegeDescription: programOwner.collegeDescription,
          departmentAcadOrg: programOwner.departmentAcadOrg,
          departmentDescription: programOwner.departmentDescription,
        });
      }

      if (programOwner.percentOwned > highestPercent) {
        highestPercent = programOwner.percentOwned;
        mainOwner = programOwner;
      }
    });
  }

  const handleOpenModal = (view) => {
    setModalView(view);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setModalView('');
    setShowModal(false);
    setShowHistoryModal(false);
  };

  const handleOpenHistoryModal = (view) => {
    setShowHistoryModal(true);
  };

  const {
    mutate: updateChecksheet,
    error: updateChecksheetError,
    isError: updateChecksheetIsError,
  } = useMutation({
    mutationFn: checksheetUpdate,
    onSuccess: () => {
      console.log('Checksheet updated successfully');
      refreshValidation();
    },
    onError: (error) => {
      console.error('Error updating checksheet:', error);
    },
  });

  const {
    mutate: updateChecksheetProgress,
    // error: updateChecksheetProgressError,
  } = useMutation({
    mutationFn: checksheetUpdateProgress,
    onSuccess: () => {
      setTimeout(() => {
        // lockChecksheet({
        //   id: checkId,
        //   jsonData: { action: 'unlock' },
        //   token,
        // });
        refetchChecksheet();
        refetchValidation();
        refetchHistory();
        setPreventLock(false);
      }, 1000);
    },
    onError: () => {
      setPreventLock(false);
    },
  });

  // const {
  //   data: checksheetData,
  //   error: getChecksheetError,
  //   isError: getChecksheetIsError,
  //   isSuccess: getChecksheetIsSuccess,
  //   isPending,
  //   fetchStatus,
  //   refetch: refetchChecksheet,
  // } = useQuery({
  //   queryKey: ['checksheet', checkId],
  //   queryFn: async () => {
  //     const token = await getAccessToken();
  //     return checksheetGet({ id: checkId, token });
  //   },
  //   enabled: !!checkId,
  // });

  const {
    mutate: requestChecksheetRecall,
    // error: updateChecksheetProgressError,
  } = useMutation({
    mutationFn: checksheetRequestRecall,
    onSuccess: () => {
      setTimeout(() => {
        refetchHistory();
        refetchChecksheet();
      }, 2000);
    },
    onError: () => {
      setPreventLock(false);
    },
  });

  // const {
  //   data: templateData,
  //   error: getTemplateError,
  //   isError: getTemplateIsError,
  //   isSuccess: getTemplateIsSuccess,
  //   refetch: refetchTemplate,
  // } = useQuery({
  //   queryKey: ['template', templateId],
  //   queryFn: async () => {
  //     const token = await getAccessToken();
  //     return templateGet({ id: templateId, token });
  //   },
  //   enabled: !!templateId,
  // });

  // const { mutate: fetchProgram } = useMutation({
  //   mutationFn: fetchDplUndergradProgramsByAcadPlanCode,
  //   onSuccess: (data) => {
  //     setProgram(data);
  //     if (!!data.subplans?.length) {
  //       const foundSubplan = data.subplans.find(
  //         (subplan) => subplan.acadSubPlanCode === temporaryAcadsubplan
  //       );

  //       if (foundSubplan) {
  //         setSubplanName(foundSubplan.description);
  //       }
  //     }
  //   },
  // });

  const {
    data: validationData,
    error: getValidationError,
    isError: getValidationIsError,
    isSuccess: getValidationIsSuccess,
    refetch: refetchValidation,
  } = useQuery({
    queryKey: ['validation', checksheetId],
    queryFn: async () => {
      const token = await getAccessToken();
      return checksheetValidationGet({ id: checksheetId, token });
    },
    enabled: false,
  });

  const {
    mutate: lockChecksheet,
    error: lockChecksheetError,
    isError: lockChecksheetIsError,
  } = useMutation({
    mutationFn: checksheetLock,
    onSuccess: () => {
      console.log('Checksheet locked successfully');
      refetchChecksheet();
      refetchValidation();
      refetchHistory();
      setPreventLock(false);
    },
    onError: () => {
      setPreventLock(false);
    },
  });

  // const { mutate: verifyChecksheet } = useMutation({
  //   mutationFn: checksheetGet,
  // });

  const handleLockUnlock = async (action) => {
    let lockCheck = true;

    setPreventLock(true);

    const jsonData = {
      action,
    };

    const token = await getAccessToken();
    // const verifyCheck = await verifyChecksheet({ id: checkId, token });

    // if (
    //   !!verifyCheck?.lockedBy &&
    //   JSON.parse(verifyCheck.lockedBy).asurite !== asurite
    // ) {
    //   lockCheck = false;
    // }

    if (lockCheck) {
      await lockChecksheet({
        id: checkId,
        jsonData,
        token,
      });
    } else {
      setPreventLock(() => false);
      alert(
        'Another user has locked this checksheet. Refresh the page to see new updates.'
      );
    }

    if (lockChecksheetIsError) setError(lockChecksheetError.message);
  };

  // click Edit button to open modal and edit the data
  const openEditModal = (index, type, details, componentId, subsectionId) => {
    const modalData = {
      index: index,
      type: type,
      componentId: componentId,
      details: details,
      subsectionId: subsectionId,
    };

    setDataToModal(modalData);
    console.log('modal data:', modalData);
  };

  const refreshValidation = useCallback(() => {
    if (checksheetId && checksheetId !== '') {
      refetchValidation();

      let number = 0;

      const getGeneralStudiesValidationList = (
        objValidGSHours,
        objValidGSUpperDivisionHours,
        objGSHours,
        objGSUpperDivisionHours
      ) => {
        return Object.entries(objValidGSHours).map(([key, value]) => {
          const gs_label =
            StaticData.gsList.find((item) => item.value === key)?.label || ''; // Find the gs label corresponding to the key
          const creditHours = {
            goal: value || 0,
            actual: objGSHours[key] || 0,
          };
          const upperDivisionHours = {
            goal: objValidGSUpperDivisionHours[key] || 0,
            actual: objGSUpperDivisionHours[key] || 0,
          };

          if (
            creditHours.actual >= creditHours.goal &&
            upperDivisionHours.actual >= upperDivisionHours.goal
          ) {
            number++;
          }
          return {
            [key]: { creditHours, upperDivisionHours, gs_label }, // Adding gs_label property to the value
          };
        });
      };

      console.log('getValidationIsSuccess: ', getValidationIsSuccess);

      if (getValidationIsError) {
        setError(getValidationError.message);
      }

      if (getValidationIsSuccess) {
        console.log('validationDataaaaa: ', validationData);

        if (validationData) {
          const generalStudiesList = getGeneralStudiesValidationList(
            validationData.validGSHours,
            validationData.validGSUpperDivisionHours,
            validationData.totalGSHours,
            validationData.totalGSUpperDivisionHours
          );

          dispatch(
            setValidationAll({
              checksheetId: checksheetId,
              totalHours: validationData.totalHours,
              totalUpperDivisionHours: validationData.totalUpperDivisionHours,
              totalHoursPerSubsection: validationData.totalHoursPerSubsection,
              totalUpperDivisionHoursPerSubsection:
                validationData.totalUpperDivisionHoursPerSubsection,
              components: validationData.components,
              numberOfRulesValid: number,
              generalStudiesList: generalStudiesList,
              valid: validationData.valid,
            })
          );

          if (
            number === generalStudiesList.length &&
            (((!validationData.validMaxHours ||
              validationData.validMaxHours < validationData.validTotalHours) &&
              validationData.totalHours >= validationData.validTotalHours) ||
              (!!validationData.validMaxHours &&
                ((validationData.validMaxHours >=
                  validationData.validTotalHours &&
                  validationData.totalHours <= validationData.validMaxHours &&
                  validationData.totalHours >=
                    validationData.validTotalHours) ||
                  (validationData.validMaxHours ===
                    validationData.validTotalHours &&
                    validationData.totalHours ===
                      validationData.validTotalHours)))) &&
            validationData.totalUpperDivisionHours >=
              validationData.validUpperDivisionHours
          ) {
            setIsValid(true);
          } else {
            setIsValid(false);
          }
        }
      }
    }
  }, [
    checksheetId,
    refetchValidation,
    getValidationIsSuccess,
    getValidationIsError,
    getValidationError,
    validationData,
    dispatch,
  ]);

  // const receiveDataFromComponent = (data) => {
  //   console.log('Received data from component========:' + JSON.stringify(data));
  //   if (data) {
  //     updateNotes(data.checksheetComponentNotes, data.componentId);
  //   }
  // };

  // Function to update parent data from a child

  const updateData = async (
    index,
    type,
    newData,
    componentId,
    subsectionId,
    oldIndex,
    oldSubsectionId
  ) => {
    console.log(
      'updateData: ',
      index,
      type,
      newData,
      componentId,
      subsectionId,
      oldIndex,
      oldSubsectionId
    );

    console.log('checksheetRequirementList:::::', checksheetRequirementList);

    const subsections = checksheetRequirementList
      ? checksheetRequirementList[componentId]?.subsections
      : {};

    if (index === 9999 && subsections && subsections[subsectionId]) {
      index = subsections[subsectionId].requirements?.length || 0;
    } else if (index === 9999) {
      console.log('index: ', index);
      index = 0;
    }

    let updatedSubsections = { ...(subsections || {}) };
    console.log('subsections:', subsections);

    console.log('newdata::::', newData);

    let updatedDataList = subsections?.[subsectionId]?.requirements
      ? [...subsections[subsectionId].requirements]
      : [];

    if (
      !newData ||
      (typeof newData === 'object' && Object.keys(newData).length === 0) ||
      !isEqual(updatedDataList[index], newData)
    ) {
      if (
        !newData ||
        (typeof newData === 'object' && Object.keys(newData).length === 0)
      ) {
        // if newData is empty (delete data)
        updatedDataList.splice(index, 1);
      } else {
        // if new data is not empty, then update data
        updatedDataList[index] = newData;
      }
    }
    let updatedObject = {
      requirements: updatedDataList,
      subsectionName: subsections?.[subsectionId]?.subsectionName || '',
      hours: subsections?.[subsectionId]?.hours || 0,
      upperDivisionHours: subsections?.[subsectionId]?.upperDivisionHours || 0,
    };

    if (
      updatedObject.requirements.length === 0 &&
      updatedObject.subsectionName.trim() === ''
    ) {
      delete updatedSubsections[subsectionId];
    } else {
      updatedSubsections[subsectionId] = updatedObject;
    }

    //if we move a requirement from one subsection to anther, we want to remove it from the old subsection
    if (oldIndex !== 9999 && oldSubsectionId) {
      updatedDataList = updatedSubsections?.[oldSubsectionId]?.requirements
        ? [...updatedSubsections[oldSubsectionId].requirements]
        : [];

      updatedDataList.splice(oldIndex, 1);
      updatedObject = {
        requirements: updatedDataList,
        subsectionName:
          updatedSubsections?.[oldSubsectionId]?.subsectionName || '',
        hours: updatedSubsections?.[oldSubsectionId]?.hours || 0,
        upperDivisionHours:
          updatedSubsections?.[oldSubsectionId]?.upperDivisionHours || 0,
      };

      if (
        updatedObject.requirements.length === 0 &&
        updatedObject.subsectionName.trim() === ''
      ) {
        delete updatedSubsections[oldSubsectionId];
      } else {
        updatedSubsections[oldSubsectionId] = updatedObject;
      }
    }

    const next = {};

    let added = false;

    console.log('newSubsctions========', updatedSubsections);

    if (checksheetRequirementList) {
      for (const id in checksheetRequirementList) {
        if (checksheetRequirementList.hasOwnProperty(id)) {
          const item = checksheetRequirementList[id];
          if (componentId === id) {
            next[componentId] = { subsections: updatedSubsections };
            added = true;
            console.log('add new item to existing componentId: ', componentId);
          } else {
            console.log('add existing item to existing componentId: ', id);
            next[id] = { subsections: item.subsections };
          }
        }
      }
      if (!added) {
        // add new item to new component
        console.log('add new item to new componentId: ', componentId);
        next[componentId] = { subsections: updatedSubsections };
      }
    } else {
      next[componentId] = { subsections: updatedSubsections }; // if no requirements exist, then this is the first new item in the list
    }

    console.log('next=========', next);

    dispatch(setChecksheetRequirementList(next));
    const jsonData = {
      year: year.value,
      templateId: templateId,
      checksheetName: checksheetName,
      programType: programType,
      acadplan: acadplan,
      acadsubplan: acadsubplan,
      college: college,
      department: department,
      componentRequirements: next,
      courseLists: courseLists,
    };
    const token = await getAccessToken();
    const resp = await updateChecksheet({
      id: checksheetId,
      jsonData: jsonData,
      token: token,
    });
    console.log('resp:::::', resp);
    refetchHistory();

    if (updateChecksheetIsError) setError(updateChecksheetError.message);
  };

  const updateSubsection = async (data, subsectionId, componentId, action) => {
    console.log('Updating subsectionname...');
    console.log('subsection data ', data);

    const subsections = checksheetRequirementList
      ? checksheetRequirementList[componentId]?.subsections
      : {};

    const updatedSubsections = { ...(subsections || {}) };

    console.log('subsections:', subsections);

    const subId = subsectionId !== '999' ? subsectionId : uuidv4();

    const updatedDataList = subsections?.[subId]?.requirements
      ? [...subsections[subId].requirements]
      : [];

    const updatedObject = {
      requirements: updatedDataList,
      subsectionName:
        action === 'subsectionName'
          ? data
          : subsections?.[subId]?.subsectionName || '',
      hours: action === 'hours' ? data : subsections?.[subId]?.hours || 0,
      upperDivisionHours:
        action === 'upperDivisionHours'
          ? data
          : subsections?.[subId]?.upperDivisionHours || 0,
    };

    updatedSubsections[subId] = updatedObject;

    console.log('new subsection data: ', updatedSubsections);

    const next = {};

    let added = false;

    if (checksheetRequirementList) {
      for (const id in checksheetRequirementList) {
        if (checksheetRequirementList.hasOwnProperty(id)) {
          const item = checksheetRequirementList[id];
          if (componentId === id) {
            next[componentId] = { subsections: updatedSubsections };
            added = true;
            console.log('add new item to existing componentId: ', componentId);
          } else {
            console.log('add existing item to existing componentId: ', id);
            next[id] = { subsections: item.subsections };
          }
        }
      }
      if (!added) {
        // add new item to new component
        console.log('add new item to new componentId: ', componentId);
        next[componentId] = { subsections: updatedSubsections };
      }
    } else {
      next[componentId] = { subsections: updatedSubsections }; // if no requirements exist, then this is the first new item in the list
    }

    console.log('next=========', next);

    dispatch(setChecksheetRequirementList(next));
    const jsonData = {
      year: year.value,
      templateId: templateId,
      checksheetName: checksheetName,
      programType: programType,
      acadplan: acadplan,
      acadsubplan: acadsubplan,
      college: college,
      department: department,
      componentRequirements: next,
      courseLists: courseLists,
    };

    console.log(jsonData);
    const token = await getAccessToken();
    updateChecksheet({ id: checksheetId, jsonData: jsonData, token: token });

    if (updateChecksheetIsError) setError(updateChecksheetError.message);
  };

  const handleProgressUpdate = async (message, action, updatedProgress) => {
    const jsonData = {
      ids: [checksheetId],
      progress: updatedProgress,
      action,
      note: message,
    };
    const token = await getAccessToken();

    await updateChecksheetProgress({
      jsonData: jsonData,
      token: token,
    });
  };

  const handleRequestRecall = async (message, action) => {
    const jsonData = {
      action,
      note: message,
    };
    const token = await getAccessToken();

    await requestChecksheetRecall({
      id: checksheetId,
      jsonData: jsonData,
      token: token,
    });
  };

  // const updateNotes = async (newData, componentId) => {
  //   const requirements =
  //     checksheetRequirementList && checksheetRequirementList[componentId]
  //       ? checksheetRequirementList[componentId].requirements
  //       : [];

  //   const next = {};
  //   const newObj = {
  //     notes: newData,
  //     requirements: requirements,
  //   };

  //   var added = false;

  //   if (checksheetRequirementList) {
  //     for (const id in checksheetRequirementList) {
  //       if (checksheetRequirementList.hasOwnProperty(id)) {
  //         const item = checksheetRequirementList[id];
  //         if (componentId === id) {
  //           next[componentId] = newObj;
  //           added = true;
  //         } else {
  //           next[id] = item;
  //         }
  //       }
  //     }
  //     if (added === false) {
  //       // add new item to new component
  //       next[componentId] = newObj;
  //     }
  //   } else {
  //     next[componentId] = newObj; // if no requirements exist, then this is the first new item in the list
  //   }

  //   dispatch(setChecksheetRequirementList(next));
  //   const jsonData = {
  //     year: year.value,
  //     templateId: templatetemplateId,
  //     checksheetName: checksheetName,
  //     programType: programType,
  //     acadplan: acadplan,
  //     acadsubplan: acadsubplan,
  //     college: college,
  //     department: department,
  //     componentRequirements: next,
  //     courseLists: courseLists,
  //   };
  //   console.log('JSON: ', jsonData);
  //   const token = await getAccessToken();
  //   updateChecksheet({ id: checksheetId, jsonData: jsonData, token: token });

  //   if (updateChecksheetIsError) setError(updateChecksheetError.message);
  // };

  const getRequirementText = (req) => {
    let division = '';
    let checkCourses = '';

    if (req.division?.trim()) {
      division =
        req.division === 'Upper' || req.division === 'Lower'
          ? req.division + ' Division '
          : req.division + '-Level ';
    }

    if (
      req.checkType === 'gpa' &&
      req.checkSubtype === 'courses' &&
      !!req.checkCourses?.length
    ) {
      req.checkCourses.forEach((checkCourse, index, array) => {
        if (index !== 0 && index === array.length - 1) checkCourses += 'and ';

        checkCourses += checkCourse.subject + ' ' + checkCourse.number;

        if (array.length > 2 && index < array.length - 1) checkCourses += ', ';
        else checkCourses += ' ';
      });
    }

    if (req.itemType === 'req_course')
      return `${req.subject} ${req.number}: ${req.topic?.description ?? !!req.proposedTitle ? req.proposedTitle : req.description}`;
    if (req.itemType === 'req_gs') return `${division}${req.gs}`;
    if (req.itemType === 'req_elective')
      return `${req.subject ? req.subject + ' ' : ''}${division}Elective`;
    if (req.itemType === 'req_text')
      return `${division}${req.group ? req.group + ': ' : ''}${req.groupIndex === 'custom_text' ? req.customText : req.text}`;
    if (req.itemType === 'req_check') {
      const textDisplay = `${req.checkType === 'gpa' ? 'Check: ' : req.checkType === 'milestone' ? 'Milestone: ' : ''}${
        req.gpa ? `Minimum ${req.gpa} ` : ''
      }${req.checkSubtype === 'asu_cum_gpa' ? 'ASU Cumulative GPA' : ''}${
        req.checkSubtype === 'major_gpa' ? 'Major GPA' : ''
      }${req.checkSubtype === 'subject' ? 'GPA in all ' + req.subject + ' ' + division + 'courses' : ''}${
        req.checkSubtype === 'courses' ? `GPA in ${checkCourses}` : ''
      }${req.milestone ? `${Object.values(req.milestone)[0]}` : ''}`;
      console.log('Text displayed: ', textDisplay);
      return textDisplay;
    }

    return null;
  };

  function handleDragStart(event) {
    let activeIds = event.active.id.split('@');
    let activeReqId = activeIds[0];
    let activeSubsectionId = activeIds[1];
    let activeSectionId = activeIds[2];

    const activeReq = checksheetRequirementList[activeSectionId].subsections[
      activeSubsectionId
    ].requirements.find((req) => req.requirementId === activeReqId);
    let activeReqName = getRequirementText(activeReq.courses[0]);

    if (activeReq.courses.length > 1) activeReqName += '...';

    setActiveName(activeReqName);
  }

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      let activeIds = active.id.split('@');
      let activeReqId = activeIds[0];
      let activeSubsectionId = activeIds[1];
      let activeSectionId = activeIds[2];
      let overIds = over.id.split('@');
      let overReqId = overIds[0];
      let overSubsectionId = overIds[1];
      let overSectionId = overIds[2];
      let activeReqIndex, overReqIndex;

      if (
        checksheetRequirementList?.[activeSectionId]?.subsections?.[
          activeSubsectionId
        ]?.requirements
      )
        checksheetRequirementList[activeSectionId].subsections[
          activeSubsectionId
        ].requirements.forEach((req, index) => {
          if (req.requirementId === activeReqId) {
            activeReqIndex = index;
          }
        });

      if (
        checksheetRequirementList?.[overSectionId]?.subsections?.[
          overSubsectionId
        ]?.requirements
      )
        checksheetRequirementList[overSectionId].subsections[
          overSubsectionId
        ].requirements.forEach((req, index) => {
          if (req.requirementId === overReqId) {
            overReqIndex = index;
          }
        });

      const updatedRequirementList = { ...checksheetRequirementList };
      const activeSection = {
        ...updatedRequirementList[activeSectionId],
      };
      const activeSectionSubs = { ...activeSection.subsections };
      const overSection = {
        ...updatedRequirementList[overSectionId],
      };
      const overSectionSubs = { ...overSection.subsections };

      if (
        activeSectionId === overSectionId &&
        activeSubsectionId === overSubsectionId
      ) {
        const activeSubsection = {
          ...activeSectionSubs[activeSubsectionId],
        };

        activeSubsection.requirements = arrayMove(
          activeSubsection.requirements,
          activeReqIndex,
          overReqIndex
        );

        activeSectionSubs[activeSubsectionId] = activeSubsection;
        activeSection.subsections = activeSectionSubs;
        updatedRequirementList[activeSectionId] = activeSection;
      } else if (activeSectionId === overSectionId) {
        const activeSubsection = {
          ...activeSectionSubs[activeSubsectionId],
        };
        const overSubsection = {
          ...activeSectionSubs[overSubsectionId],
        };

        if (!Object.keys(overSubsection).length) {
          overSubsection.subsectionName = '';
          overSubsection.requirements = [];
          overSubsection.hours = 0;
          overSubsection.upperDivisionHours = 0;
        }

        const activeRequirements = [...activeSubsection.requirements];
        const overRequirements = [...overSubsection.requirements];

        const [movedRequirement] = activeRequirements.splice(activeReqIndex, 1);

        overRequirements.splice(overReqIndex, 0, movedRequirement);

        activeSubsection.requirements = activeRequirements;
        overSubsection.requirements = overRequirements;

        activeSectionSubs[activeSubsectionId] = activeSubsection;
        activeSectionSubs[overSubsectionId] = overSubsection;

        activeSection.subsections = activeSectionSubs;
        updatedRequirementList[activeSectionId] = activeSection;
      } else {
        const activeSubsection = {
          ...activeSectionSubs[activeSubsectionId],
        };
        const overSubsection = {
          ...overSectionSubs[overSubsectionId],
        };

        if (!Object.keys(overSubsection).length) {
          overSubsection.subsectionName = '';
          overSubsection.requirements = [];
          overSubsection.hours = 0;
          overSubsection.upperDivisionHours = 0;
        }

        const activeRequirements = [...activeSubsection.requirements];
        const overRequirements = [...overSubsection.requirements];

        const [movedRequirement] = activeRequirements.splice(activeReqIndex, 1);

        overRequirements.splice(overReqIndex, 0, movedRequirement);

        activeSubsection.requirements = activeRequirements;
        overSubsection.requirements = overRequirements;

        activeSectionSubs[activeSubsectionId] = activeSubsection;
        activeSection.subsections = activeSectionSubs;
        updatedRequirementList[activeSectionId] = activeSection;

        overSectionSubs[overSubsectionId] = overSubsection;
        overSection.subsections = overSectionSubs;
        updatedRequirementList[overSectionId] = overSection;
      }

      dispatch(setChecksheetRequirementList(updatedRequirementList));

      const jsonData = {
        year: year.value,
        templateId: templateId,
        checksheetName: checksheetName,
        programType: programType,
        acadplan: acadplan,
        acadsubplan: acadsubplan,
        college: college,
        department: department,
        componentRequirements: updatedRequirementList,
        courseLists: courseLists,
      };

      console.log(jsonData);

      const token = await getAccessToken();

      updateChecksheet({
        id: checksheetId,
        jsonData,
        token,
      });
    }

    setShowOverlay(false);
    setActiveName('');
  };

  const handleDragOver = (event) => {
    const { active, over } = event;

    if (!active || !over) {
      setShowOverlay(true);
      return;
    }

    const activeId = active.id;
    const overId = over.id;

    // Split IDs to get subsection and requirement IDs
    let activeIds = activeId.split('@');
    let activeSubsectionId = activeIds[1];
    let overIds = overId.split('@');
    let overReqId = overIds[0];
    let overSubsectionId = overIds[1];

    if (activeSubsectionId !== overSubsectionId || !overReqId)
      setShowOverlay(true);
    else setShowOverlay(false);
  };

  useEffect(() => {
    const progressReviewObj = StaticData.progressReview.find(
      (item) => item.review === progress
    );

    if (progressReviewObj) {
      setPreviousReview(progressReviewObj.previousReview);
      setNextReview(progressReviewObj.nextReview);
    }
  }, [progress]);

  useEffect(() => {
    if (!!program.subplans?.length) {
      const foundSubplan = program.subplans.find(
        (subplan) => subplan.acadSubPlanCode === paramAcadSubplan
      );

      if (foundSubplan) {
        setSubplanName(foundSubplan.description);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  useEffect(() => {
    if (checkId !== '' && checkId !== null) {
      if (getChecksheetIsError) setError(getChecksheetError.message);
      // console.log('getChecksheetIsSuccess: ', getChecksheetIsSuccess);
      if (getChecksheetIsSuccess) {
        // console.log('checksheetData: ', JSON.stringify(checksheetData));

        // if checksheet exist
        if (checksheetData) {
          const jsonDetails = JSON.parse(checksheetData['details']);
          const yearValue = StaticData.yearList.find(
            (obj) => obj.value === jsonDetails.year
          );
          // console.log('yearvalue: ', yearValue);
          // console.log(
          //   'jsonDetails.componentRequirements: ',
          //   jsonDetails.componentRequirements
          // );

          let lockedOrUnlocked = {};

          if (!!checksheetData['lockedBy']) {
            lockedOrUnlocked = JSON.parse(checksheetData['lockedBy']);
          }

          dispatch(
            setChecksheetAll({
              checksheetId: checksheetData['sk'],
              checksheetName: jsonDetails.checksheetName,
              year: yearValue,
              templateId: jsonDetails.templateId,
              programType: jsonDetails.programType,
              acadplan: jsonDetails.acadplan,
              acadsubplan: jsonDetails.acadsubplan,
              // acadplanDescription: jsonDetails.acadplanDescription,
              college: jsonDetails.college,
              department: jsonDetails.department,
              checksheetRequirementList: jsonDetails.componentRequirements,
              progress: checksheetData['progress'],
              lockedBy: lockedOrUnlocked,
              recalledBy: checksheetData['recalledBy']
                ? JSON.parse(checksheetData['recalledBy'])
                : {},
              courseLists: jsonDetails.courseLists,
              details: jsonDetails,
            })
          );

          if (checksheetData?.templateObject.details) {
            const templateJsonDetails = JSON.parse(
              checksheetData.templateObject['details']
            );
            const templateYearValue = StaticData.yearList.find(
              (obj) => obj.value === templateJsonDetails.year
            );

            dispatch(
              setAll({
                templateId: checksheetData.templateObject['sk'],
                year: templateYearValue,
                templateName: templateJsonDetails.templateName,
                templateSettings: templateJsonDetails.settings,
                templateComponentIds: templateJsonDetails.components,
                templateComponentObjects:
                  checksheetData.templateObject['componentObjects'],
                templateValidationList: templateJsonDetails.validationRules,
                templateTextOptions: templateJsonDetails.textOptions,
                templateMilestones: templateJsonDetails.milestones,
              })
            );
          } else setTemplateNotFound(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChecksheetIsSuccess, checksheetData, checkId]);

  useEffect(() => {
    refreshValidation();
    refetchHistory();
  }, [refreshValidation, refetchHistory]);

  useEffect(() => {
    let findOwner = false;

    if (program.owners && program.owners.length > 0) {
      program.owners.forEach((programOwner) => {
        if (
          userCollege === programOwner.collegeAcadOrg ||
          userDepartment.includes(programOwner.departmentAcadOrg) ||
          role.includes('PROVOST') ||
          role.includes('DARS')
        ) {
          findOwner = true;
        }
      });
    }
    if (findOwner === false) {
      setReadOnly(true);
    } else {
      if (role.includes(progress)) {
        setReadOnly(false);
      }
    }
  }, [program, userCollege, userDepartment, readOnly, role, progress]);

  if (checkId && isPending && fetchStatus === 'fetching')
    return <div className="container">Loading...</div>;

  if (
    // getTemplateIsError ||
    getChecksheetIsError ||
    // fetchProgramsIsError ||
    getValidationIsError ||
    updateChecksheetIsError
  )
    return (
      <div>
        <div>An error has occurred: {error}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <>
      <div>
        <div
          className="template-header bg-white border-bottom border-gray-4 position-fixed"
          style={{ width: '80%', zIndex: 1 }}
        >
          {checksheetData && (
            <div
              className={classNames(
                isValid &&
                  ((role.includes(progress) &&
                    progress !== 'PROVOST' &&
                    progress !== 'DARS') ||
                    progress === 'COMPLETE')
                  ? 'bg-success-custom'
                  : 'bg-gray-1 border-bottom border-gray-4',
                'd-flex py-1'
              )}
              style={{ width: '100%' }}
            >
              <div className="container d-flex justify-content-between align-items-center">
                {!!Object.keys(recalledBy).length ? (
                  <div className="d-flex gap-2">
                    <div className="fw-bold" style={{ padding: '5px 0' }}>
                      Recall request pending review
                    </div>
                    {currentTab !== 2 && (
                      <Button
                        label="View request"
                        color="gold"
                        size="small"
                        onClick={() => setCurrentTab(2)}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    <div className="d-flex gap-2">
                      {isValid ? (
                        progress === 'COMPLETE' ? (
                          <div className="fw-bold" style={{ padding: '5px 0' }}>
                            The checksheet is complete!
                          </div>
                        ) : !!progress && !role.includes(progress) ? (
                          <div className="fw-bold" style={{ padding: '5px 0' }}>
                            The checksheet is pending{' '}
                            {
                              StaticData.statusRoles.find(
                                (statusRole) => statusRole.value === progress
                              ).label
                            }{' '}
                            review.
                          </div>
                        ) : role.includes('PROVOST') ||
                          role.includes('DARS') ? (
                          <div className="fw-bold" style={{ padding: '5px 0' }}>
                            This checksheet is pending approval.
                          </div>
                        ) : !!nextReview ? (
                          <div
                            className="d-flex gap-1 align-items-center"
                            style={{ padding: '5px 0' }}
                          >
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="text-success-custom"
                            />
                            <div className="fw-bold">
                              Checksheet satisfies all rules and may be
                              submitted for{' '}
                              {
                                StaticData.statusRoles.find(
                                  (statusRole) =>
                                    statusRole.value === nextReview
                                ).label
                              }{' '}
                              review.
                            </div>
                          </div>
                        ) : (
                          <div className="fw-bold" style={{ padding: '5px 0' }}>
                            This checksheet has been completed
                          </div>
                        )
                      ) : (
                        <div className="fw-bold" style={{ padding: '5px 0' }}>
                          This checksheet does not meet the validation
                          requirements
                        </div>
                      )}
                      {!Object.keys(recalledBy).length &&
                        !!previousReview &&
                        ((role.includes('PROVOST') && progress === 'DARS') ||
                          (role.includes('COLLEGE') &&
                            userCollege === college &&
                            (progress === 'PROVOST' || progress === 'DARS')) ||
                          (role.includes('DEPARTMENT') &&
                            userDepartment.includes(department) &&
                            (progress === 'COLLEGE' ||
                              progress === 'PROVOST' ||
                              progress === 'DARS'))) && (
                          <Button
                            label={
                              role.includes('PROVOST')
                                ? 'Recall'
                                : 'Request a recall'
                            }
                            color="dark"
                            size="small"
                            onClick={() => handleOpenModal('recall')}
                          />
                        )}
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      {role.includes(progress) &&
                        !!previousReview &&
                        isLocked &&
                        holdsLock &&
                        (role.includes('DARS') ||
                          role.includes('PROVOST') ||
                          (role.includes('COLLEGE') &&
                            userCollege === college)) && (
                          <div
                            className="button-link"
                            onClick={() => handleOpenModal('return')}
                            role="button"
                          >
                            Return checksheet
                          </div>
                        )}
                      {role.includes(progress) &&
                        !!nextReview &&
                        isValid &&
                        (isLocked && holdsLock ? (
                          progress === 'PROVOST' || progress === 'DARS' ? (
                            <Button
                              label="Approve"
                              color="gold"
                              size="small"
                              icon={['fa', 'check']}
                              onClick={() => handleOpenModal('submit')}
                            />
                          ) : (
                            <Button
                              label="Submit"
                              color="dark"
                              size="small"
                              onClick={() => handleOpenModal('submit')}
                            />
                          )
                        ) : (
                          <div>
                            Checksheet must be locked before{' '}
                            {progress === 'PROVOST' || progress === 'DARS'
                              ? 'approval'
                              : 'submission'}
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          <div className="container mt-2">
            <div className="row">
              <div className="col-6">
                {readOnly && checksheetData && (
                  <div className="text-maroon">(Read Only)</div>
                )}
                <div className="fs-4 fw-bold">{checksheetName}</div>
                {!!subplanName && (
                  <div className="fw-bold mb-1">
                    <span>{subplanName}</span>
                    <span className="subplan-tag ms-1">Subplan</span>
                  </div>
                )}
                <div style={{ fontSize: '14px' }}>
                  {owners &&
                    owners.map((owner) => (
                      <div key={owner.collegeAcadOrg}>
                        <span>
                          <span>{owner.collegeDescription} / </span>
                          {/* {owner.departmentDescription} ({owner.departmentAcadOrg}) */}
                          <span>{acadplan} / </span>
                          {paramAcadSubplan !== 'null' && (
                            <span>{paramAcadSubplan} / </span>
                          )}
                          <span>{year.label}</span>
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-6">
                <div>
                  <div
                    className="d-flex gap-3 align-items-center justify-content-end mb-1"
                    style={{ padding: '6px 0' }}
                  >
                    {progress && (
                      <div className="d-flex gap-1 align-items-center fw-bold">
                        <FontAwesomeIcon
                          icon={faCircle}
                          className={classNames(statusCircle[progress].color)}
                          width={'16px'}
                        />
                        <div>
                          {
                            StaticData.statusList.find(
                              (status) => status.value === progress
                            ).label
                          }
                        </div>
                      </div>
                    )}
                    {lockedBy && !!Object.keys(lockedBy).length && (
                      <div className="d-flex gap-1 align-items-center bg-gray-2 px-1 rounded-1">
                        <FontAwesomeIcon
                          icon={faLock}
                          className="text-gray-6"
                          style={{ width: '12px' }}
                        />
                        <div>Locked by {lockedBy.asurite}</div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex gap-3 align-items-center justify-content-end">
                    <div
                      className="d-flex gap-1 align-items-center"
                      style={{ color: 'maroon' }}
                    >
                      <FontAwesomeIcon icon={faBookOpen} />{' '}
                      <a
                        href={`/checksheet-catalog-changes?id=${checksheetId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Check for catalog changes
                      </a>
                    </div>

                    <Button
                      label="Preview"
                      size="small"
                      color="maroon"
                      icon={['fa', 'external-link']}
                      style={{ color: 'white' }}
                      href={`${previewUrl}/${year.value}/${college}/${acadplan}/${acadsubplan}`}
                      target="_blank"
                    />

                    {!Object.keys(recalledBy).length && (
                      <>
                        {isLocked &&
                          (holdsLock || role.includes('PROVOST')) && (
                            <Button
                              label="Unlock"
                              size="small"
                              icon={['fa', 'unlock']}
                              classes={[
                                'btn btn-white btn-md border border-maroon text-maroon',
                              ]}
                              onClick={() => handleLockUnlock('unlock')}
                              disabled={preventLock}
                            />
                          )}

                        {!isLocked &&
                          !!progress &&
                          role.includes(progress) &&
                          (role.includes('DARS') ||
                            role.includes('PROVOST') ||
                            (role.includes('COLLEGE') &&
                              userCollege === college) ||
                            (role.includes('DEPARTMENT') &&
                              userDepartment.includes(department))) && (
                            <Button
                              label="Lock"
                              size="small"
                              color="maroon"
                              icon={['fa', 'lock']}
                              onClick={() => handleLockUnlock('lock')}
                              disabled={preventLock}
                            />
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3">
              {checksheetData &&
                componentIdNames.map((componentIdName, index) => (
                  <a
                    key={index}
                    href={`#${componentIdName.componentId}`}
                    onClick={() => setActiveTab(index)}
                    className={classNames(
                      activeTab === index
                        ? 'active-tab fw-bold'
                        : 'inactive-tab',
                      'pt-2 pb-1 d-flex justify-content-center text-gray-7 text-decoration-none'
                    )}
                  >
                    {componentIdName.componentName}
                  </a>
                ))}
            </div>
          </div>
        </div>
        {/* TODO: Might need to adjust padding based off of height of header, I need to think about this */}
        <div className="container" style={{ paddingTop: `250px` }}>
          <div className="row no-gutters">
            <div className="col uds-full-width">
              {!templateNotFound ? (
                <>
                  <div className="pt-2">
                    <DndContext
                      sensors={sensors}
                      collisionDetection={pointerWithin}
                      onDragStart={handleDragStart}
                      onDragEnd={handleDragEnd}
                      onDragOver={handleDragOver}
                      modifiers={[restrictToVerticalAxis]}
                    >
                      <DragOverlay
                        style={{
                          width: '50%',
                        }}
                        dropAnimation={null}
                      >
                        {showOverlay ? (
                          <div className="px-4 py-2 fw-bold me-4 d-flex bg-white border border-gray1">
                            {activeName}
                          </div>
                        ) : null}
                      </DragOverlay>
                      {templateComponentIds.map((componentId) => (
                        <div
                          key={componentId}
                          className="mb-9"
                          id={componentId}
                        >
                          <Component
                            id={componentId}
                            year={year.value}
                            checksheet={true}
                            isLocked={isLocked && !role.includes('DARS')}
                            holdsLock={holdsLock}
                            openEditModal={openEditModal}
                            updateData={updateData}
                            dataToModal={dataToModal}
                            // dataToParent={(newData) =>
                            //   receiveDataFromComponent(newData)
                            // }
                            updateSubsection={updateSubsection}
                            readOnly={readOnly}
                            history={historyRequirements}
                            historySubsections={historySubsections}
                          />
                        </div>
                      ))}
                    </DndContext>
                  </div>
                </>
              ) : (
                <div>
                  The template associated with this checksheet was not found.
                </div>
              )}

              {historyDeleted && Object.keys(historyDeleted).length > 0 && (
                <div
                  className="d-flex gap-1 align-items-center bg-gray-2 py-1 px-4 rounded-4 m-4"
                  style={{ cursor: 'pointer', width: 'fit-content' }}
                  onClick={handleOpenHistoryModal}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    title="Delete requirements"
                    className="deleteButton"
                    style={{ width: '18px', marginRight: '8px' }} // Adjust icon size and spacing
                  />
                  <span>
                    Deleted requirements ({Object.keys(historyDeleted).length})
                  </span>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="text-success"
                    style={{ width: '10px', paddingTop: '4px' }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <ProgressUpdateForm
          view={modalView}
          progress={progress}
          updatedProgress={
            modalView === 'recall' || modalView === 'return'
              ? previousReview
              : modalView === 'submit'
                ? nextReview
                : ''
          }
          college={college}
          department={department}
          mainOwner={mainOwner}
          handleProgressUpdate={handleProgressUpdate}
          handleRequestRecall={handleRequestRecall}
          handleClose={handleCloseModal}
          role={role}
        />
      </Modal>

      {/* Modal for the deleted requirements history */}
      <Modal show={showHistoryModal} onHide={handleCloseModal}>
        <div style={{ width: '800px' }} className="p-6">
          <div
            className="border border-gray-3 rounded-circle lh-1"
            style={{ padding: '7px 10px', float: 'right' }}
            onClick={handleCloseModal}
            role="button"
          >
            <FontAwesomeIcon
              icon={faXmark}
              width={'10px'}
              height={'10px'}
              className="text-gray-7"
            />
          </div>
          {/* Loop through each deleted course list */}
          {historyDeleted && (
            <div className="table-responsive">
              <h4>Deleted Requirements</h4>
              <table className="table table-striped table-responsive">
                <thead style={{ whiteSpace: 'nowrap' }}>
                  <tr>
                    <th>Requirements</th>
                    <th>Credit Hours</th>
                    <th>Deleted By</th>
                    <th>Date and time</th>
                  </tr>
                </thead>
                <tbody>
                  {historyDeleted &&
                  Object.keys(historyDeleted).length === 0 ? (
                    <tr>
                      <td colSpan="3" style={{ textAlign: 'center' }}>
                        No history available.
                      </td>
                    </tr>
                  ) : (
                    Object.entries(historyDeleted).map(([key, items]) =>
                      items.map((item, index) => {
                        // Ensure item.before is converted to an object
                        let beforeObject;
                        try {
                          beforeObject =
                            typeof item.before === 'string'
                              ? JSON.parse(item.before)
                              : item.before;
                        } catch (error) {
                          console.error(
                            `Error parsing item.before for key ${key}:`,
                            error
                          );
                          beforeObject = {}; // Fallback to an empty object
                        }

                        return (
                          <tr key={`${key}-${index}`}>
                            <td>
                              {getCoursesDescr(item.courses, courseLists)}
                            </td>
                            <td>
                              {beforeObject.maxHours !== 0
                                ? `${beforeObject.hours} - ${beforeObject.maxHours}`
                                : beforeObject.hours}
                            </td>
                            <td>{item.updatedBy}</td>
                            <td>
                              {new Date(item.updatedOn).toLocaleDateString(
                                'en-us',
                                {
                                  month: 'short',
                                  day: 'numeric',
                                  year: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                }
                              )}
                            </td>
                          </tr>
                        );
                      })
                    )
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ChecksheetForm;
