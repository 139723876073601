// @ts-check

// React
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUserAll } from 'state/slices/userSlice';
import { useLocation, useNavigate } from 'react-router-dom';

// core
import { ASUHeader } from '@asu/component-header/dist/asuHeader.es';
import desktopLogo from '../assets/img/arizona-state-university-logo-vertical.png';
import mobileLogo from '../assets/img/arizona-state-university-logo.png';

// authorization
import useServiceAuth from 'auth/useServiceAuth';

// style
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { fetchDplColleges } from 'apis/dplAPIs';

// Some customization, only if necessary, it is not mandatory
const HeaderSection = styled.div`
  header {
    // position: sticky;

    // this align the "Create" button
    // .content-container {
    //   font-size: 1rem;
    //   border: 0;
    //   margin: 0;
    //   height: fit-content;
    //   align-items: start;
    //   flex-direction: row;
    // }

    // a {
    //   cursor: pointer;
    // }
  }
`;

const routerUrl = {
  PATHOME: '/',
  PROGRAMS: '/programs',
  TEMPLATE: '/template',
  TEMPLATES: '/templates',
  COMPONENT: '/component',
  COMPONENTS: '/components',
  ADDUSER: '/users',
  SUBPLANACTIVATION: '/subplanactivation',
  RECALLS: '/recall-requests',
  SETTINGS: '/settings',
  ROLLOVER: '/rollover',
};

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, user, logout, redirectToServiceauth } =
    useServiceAuth();
  const dispatch = useDispatch();

  const ssoName = document.cookie
    .split('; ')
    ?.find((cookie) => cookie.startsWith('SSONAME'))
    ?.split('=')[1];

  const [userRole, setUserRole] = useState('');
  const [collegeList, setCollegeList] = useState([]);
  const [stateKey, setStateKey] = useState(0);

  const {
    data: fetchCollegesData,
    // error: fetchCollegesError,
    // isError: fetchCollegesIsError,
    isSuccess: fetchCollegesIsSuccess,
  } = useQuery({
    queryKey: ['colleges'],
    queryFn: fetchDplColleges,
  });

  const navigateToPage = (e, url) => {
    e.preventDefault();
    navigate(url);
    setStateKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (fetchCollegesIsSuccess) {
      const sorted = fetchCollegesData
        // @ts-ignore
        .toSorted((a, b) =>
          a.alphaSortDescription.localeCompare(b.alphaSortDescription)
        )
        .filter(
          (college) =>
            college.acadOrgCode !== 'COE' &&
            college.acadOrgCode !== 'CGC' &&
            college.acadOrgCode !== 'CLW' &&
            college.acadOrgCode !== 'CTPH' &&
            college.acadOrgCode !== 'CPROVOST'
        );

      setCollegeList(sorted);
    }
  }, [fetchCollegesData, fetchCollegesIsSuccess]);

  useEffect(() => {
    setUserRole(sessionStorage.getItem('role'));

    dispatch(
      setUserAll({
        asurite: !!user ? user.split('@')[0] : '',
        role: sessionStorage.getItem('role'),
        college: sessionStorage.getItem('college'),
        department: sessionStorage.getItem('department'),
      })
    );
  }, [dispatch, user]);

  return (
    <HeaderSection>
      <ASUHeader
        key={stateKey}
        title="Program Architecture Tool"
        logo={{
          alt: 'logo',
          src: desktopLogo,
          mobileSrc: mobileLogo,
        }}
        loggedIn={isAuthenticated}
        userName={ssoName ? ssoName : user}
        onLoginClick={redirectToServiceauth}
        onLogoutClick={logout}
        navTree={[
          {
            // href: routerUrl.PATHOME,
            onClick: (e) => navigateToPage(e, routerUrl.PATHOME),
            text: 'Home',
            type: 'icon-home',
            selected: location.pathname === routerUrl.PATHOME ? true : false,
          },
          {
            text: 'Programs',
            selected: location.pathname === routerUrl.PROGRAMS ? true : false,
            items: [
              [
                // {
                //   text: 'View by college/school',
                //   type: 'heading',
                // },
                ...collegeList
                  .slice(0, Math.ceil(collegeList.length / 2))
                  .map((college) => ({
                    text: college.alphaSortDescription,
                    href: `${routerUrl.PROGRAMS}?college=${college.acadOrgCode}`,
                    onClick: (e) =>
                      navigateToPage(
                        e,
                        `${routerUrl.PROGRAMS}?college=${college.acadOrgCode}`
                      ),
                  })),
              ],
              [
                ...collegeList
                  .slice(Math.ceil(collegeList.length / 2))
                  .map((college) => ({
                    text: college.alphaSortDescription,
                    href: `${routerUrl.PROGRAMS}?college=${college.acadOrgCode}`,
                    onClick: (e) =>
                      navigateToPage(
                        e,
                        `${routerUrl.PROGRAMS}?college=${college.acadOrgCode}`
                      ),
                  })),

                {
                  href: routerUrl.PROGRAMS,
                  onClick: (e) => navigateToPage(e, routerUrl.PROGRAMS),
                  text: 'View all programs',
                  type: 'button',
                },
              ],
            ],
            // href: routerUrl.PROGRAMS,
            // onClick: (e) => {
            //   e.preventDefault();
            //   navigate(routerUrl.PROGRAMS);
            // },
          },
          ...(userRole && userRole.includes('PROVOST')
            ? [
                {
                  text: 'Admin',
                  items: [
                    [
                      {
                        href: routerUrl.TEMPLATES,
                        onClick: (e) => navigateToPage(e, routerUrl.TEMPLATES),
                        text: 'Templates',
                        selected:
                          location.pathname === routerUrl.TEMPLATES
                            ? true
                            : false,
                      },
                      {
                        href: routerUrl.SUBPLANACTIVATION,
                        onClick: (e) =>
                          navigateToPage(e, routerUrl.SUBPLANACTIVATION),
                        text: 'Subplans',
                        selected:
                          location.pathname === routerUrl.SUBPLANACTIVATION
                            ? true
                            : false,
                      },
                      {
                        href: routerUrl.ADDUSER,
                        onClick: (e) => navigateToPage(e, routerUrl.ADDUSER),
                        text: 'Users',
                        selected:
                          location.pathname === routerUrl.ADDUSER
                            ? true
                            : false,
                      },
                      {
                        href: routerUrl.RECALLS,
                        onClick: (e) => navigateToPage(e, routerUrl.RECALLS),
                        text: 'Recall requests',
                        selected:
                          location.pathname === routerUrl.RECALLS
                            ? true
                            : false,
                      },
                      {
                        href: routerUrl.SETTINGS,
                        onClick: (e) => navigateToPage(e, routerUrl.SETTINGS),
                        text: 'Settings',
                        selected:
                          location.pathname === routerUrl.SETTINGS
                            ? true
                            : false,
                      },
                      {
                        href: routerUrl.ROLLOVER,
                        onClick: (e) => navigateToPage(e, routerUrl.ROLLOVER),
                        text: 'Start rollover',
                        type: 'button',
                      },
                    ],
                  ],
                },
              ]
            : []),
        ]}
      />
    </HeaderSection>
  );
};

export default Header;
