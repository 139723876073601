import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  checksheetId: '',
  checksheetName: '',
  year: { value: '2023', label: '2023-2024' },
  templateId: '',
  programType: '',
  acadplan: '',
  acadsubplan: '',
  college: '',
  department: '',
  checksheetRequirementList: {},
  progress: '',
  lockedBy: {},
  recalledBy: {},
  courseLists: {},
  details: {},
};

export const checksheetSlice = createSlice({
  name: 'checksheet',
  initialState,
  reducers: {
    setChecksheetAll: (state, action) => {
      state.checksheetId = action.payload.checksheetId;
      state.checksheetName = action.payload.checksheetName;
      state.year = action.payload.year;
      state.templateId = action.payload.templateId;
      state.programType = action.payload.programType;
      state.acadplan = action.payload.acadplan;
      state.acadsubplan = action.payload.acadsubplan;
      state.college = action.payload.college;
      state.department = action.payload.department;
      state.checksheetRequirementList =
        action.payload.checksheetRequirementList;
      state.progress = action.payload.progress;
      state.lockedBy = action.payload.lockedBy;
      state.recalledBy = action.payload.recalledBy;
      state.courseLists = action.payload.courseLists;
      state.details = action.payload.details;

      return state;
    },
    setChecksheetId: (state, action) => {
      const updatedState = { ...state };
      updatedState.checksheetId = action.payload;

      return updatedState;
    },
    setYear: (state, action) => {
      const updatedState = { ...state };
      updatedState.year = action.payload;
      return updatedState;
    },
    setProgress: (state, action) => {
      const updatedState = { ...state };
      updatedState.progress = action.payload;
      return updatedState;
    },
    setLockedBy: (state, action) => {
      const updatedState = { ...state };
      updatedState.lockedBy = action.payload;
      return updatedState;
    },
    setRecalledBy: (state, action) => {
      const updatedState = { ...state };
      updatedState.recalledBy = action.payload;
      return updatedState;
    },
    setChecksheetRequirementList: (state, action) => {
      const updatedState = { ...state };
      updatedState.checksheetRequirementList = action.payload;
      return updatedState;
    },
    setCourseLists: (state, action) => {
      const updatedState = { ...state };
      updatedState.courseLists = action.payload;
      return updatedState;
    },
    setDetails: (state, action) => {
      const updatedState = { ...state };
      updatedState.details = action.payload;
      return updatedState;
    },
    clearChecksheet: (state) => {
      return { ...initialState };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setChecksheetAll,
  setProgress,
  setLockedBy,
  setRecalledBy,
  setChecksheetRequirementList,
  setCourseLists,
  setDetails,
  clearChecksheet,
} = checksheetSlice.actions;

export default checksheetSlice.reducer;
